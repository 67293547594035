import { safeURL } from './urlUtils'

export const MarkdownLinkTempParam = '_mdAttr'

/**
 * Parses italic text from backend markdown, that is not caught by 'marked' package.
 *
 * @param md
 */
export function parseItalic(md: string): string {
  return String(md).replace(/( |^)_(?!blank)(.*?)_(?!blank)/gm, '$1<em>$2</em>')
}

/**
 * Parses cite text from backend markdown, that is not caught by 'marked' package.
 *
 * @param md
 */
export function parseCite(md: string): string {
  return String(md).replace(/(^|[^~])(?:~([^~]+)~)/, '$1<cite>$2</cite>')
}

/**
 * Adds custom attribute(s) to video markdown.
 *
 * @param md
 */
export function parseVideo(md: string): string {
  return md.replace(/(\<video)/, `$1 playsinline=true `)
}

/**
 * Handles our custom way of implementing custom attributes to Markdown links.
 *
 * @param md
 */
export function parseLinks(md: string) {
  return md.replace(
    /\[\s*(.*?)\s*\]\(\s*(.*?)\s*(?:\{\s*(.*?)\s*\})?\s*\)/g,
    (_, text, url, attributes) => {
      const safeUrl = safeURL(url)
      if (!safeUrl) return _

      if (attributes) {
        // Parse attributes if present
        const parsedAttributes = attributes
          .split(',')
          .map(attr => attr.trim().split(':'))
          .map(
            ([key, value]) =>
              `${key.replace(/['"]/g, '')}="${value.replace(/['"]/g, '')}"`
          )
          .join(' ')

        // Return a full anchor tag with attributes
        return `<a href="${safeUrl.href}" ${parsedAttributes}>${text}</a>`
      } else {
        // Return the original markdown link if no attributes are present
        return `[${text}](${safeUrl.href})`
      }
    }
  )
}

/**
 * Makes sure newline characters are the same all over.
 * Backend uses \n as newline.
 *
 * @param md
 */
export function parseNewLines(md: string) {
  return md.replace(/(?:\r\n|\r|\n)/g, '\n')
}

/**
 * If a string is only whitespace, the MD parser fails. So we remove instances with this.
 *
 * @param md
 */
export function handleOnlyWhitespace(md: string) {
  return md.replaceAll(/\s/g, '').length === 0 ? '' : md
}
